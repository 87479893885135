import { patchConnectionAssuranceCommissioning } from '@/connection-assurance/commissioning/api/patchConnectionAssuranceCommissioning';
import { AcknowledgementCheckbox } from '@/connection-assurance/commissioning/completion-confirmation/AcknowledgementCheckbox';
import { CommissioningDateFormItem } from '@/connection-assurance/commissioning/completion-confirmation/CommissioningDateFormItem';
import { CommissioningData } from '@/connection-assurance/commissioning/types/CommissioningData';
import { transformContext } from '@/connection-assurance/commissioning/utils/transformContext';
import { useContextUpdate } from '@/connection-assurance/commissioning/wizard/hooks';
import useApi from '@/hooks/useApi';
import { RouteProperties } from '@/types/RouteProperties';
import { parseNumberFromString } from '@/utils/formatNumber';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { ErrorBox, Form, createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { CommissioningProcessProps } from '../CommissioningProcessProps';
import { AcknowledgeSymmetryCondition } from './AcknowledegeSymmetryCondition';

const determineReactivePowerProvisionGenerationPlantText = (isSolarEnergy: boolean, isDCStorage: boolean) => {
    if (!isSolarEnergy) {
        return (
            <span className="mt-6">
                ¹Synchrongeneratoren, Stirlinggeneratoren, Brennstoffzellen ≤&nbsp;4,6&nbsp;kVA keine Vorgabe zum
                Blindleistungsverhalten, möglichst cos&nbsp;φ&nbsp;=&nbsp;0,95 untererregt.
            </span>
        );
    }
    if (isSolarEnergy && isDCStorage) {
        return (
            <>
                Wenn Erzeugungseinheit und Speicher DC-gekoppelt sind, gelten bei Energielieferung des gemeinsamen
                Umrichters die Anforderungen von Erzeugungsanlagen.
            </>
        );
    }
    return null;
};

export const CompletionConfirmation = ({
    goBack,
    onSubmit,
    context,
    updateContext,
    title,
    connectionAssurance,
}: RouteProperties & CommissioningProcessProps) => {
    const patchConnectionAssuranceCommissioningApi = useApi(patchConnectionAssuranceCommissioning);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSubmitError, setShowSubmitError] = useState<boolean>(false);

    const {
        formState: { errors, isValid },
        register,
        trigger,
        watch,
    } = useForm<CommissioningData>({
        mode: 'onBlur',
        defaultValues: {
            technicalCommissioningDateOfGenerator: context.technicalCommissioningDateOfGenerator,
            technicalCommissioningDateOfStorage: context.technicalCommissioningDateOfStorage,
            acknowledgements: context.acknowledgements,
        },
    });

    useContextUpdate(watch, updateContext, 'COMPLETION_CONFIRMATION');

    const allPlantComponents = [...context.plantComponentsGenerator, ...context.plantComponentsStorage];

    const activePowerAbove25kW = calculateSumOfActivePowersInKW(allPlantComponents) >= 25;
    const hasGenerationPlant = context.plantComponentsGenerator?.length > 0;
    const hasEnergyStorage = context.plantComponentsStorage?.length > 0;
    const hasSinglePhaseInverter = allPlantComponents.some(
        (plantComponent) => plantComponent.inverterPhaseType === 'single-phase',
    );
    const isSolarEnergy =
        hasGenerationPlant && connectionAssurance.generatorData?.generalGeneratorType.generatorType === 'solarEnergy';
    const isDCStorage = connectionAssurance.storageData?.energyStorageSystem === 'dcSystem';

    const solarPowerAbove25kWp =
        isSolarEnergy && parseNumberFromString(context.generatorData.solarModuleCapacityInkWp) >= 25;

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setShowSubmitError(false);

        try {
            await patchConnectionAssuranceCommissioningApi({
                connectionAssuranceId: connectionAssurance.id,
                commissioningData: transformContext(context),
            });

            onSubmit();
        } catch (e) {
            console.error('Submit commissioning data failed', e);
            setShowSubmitError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid: isValid,
        isPrevValid: !isSubmitting,
        onBack: goBack,
        onNext: handleSubmit,
        nextLabel: 'Abschicken',
        loading: isSubmitting,
    });

    return (
        <Form title={title} rightButton={nextButton} leftButton={prevButton} handleSubmit={handleSubmit}>
            <div className="grid grid-cols-4 gap-4">
                <AcknowledgementCheckbox
                    trigger={trigger}
                    id="integratedGridAndPlantProtection"
                    title="Integrierter NA-Schutz"
                    label={
                        <>
                            Der integrierte NA-Schutz erfüllt folgende Eigenschaft:
                            <br />
                            Eingestellter Wert Spannungssteigerungsschutz U&nbsp;&gt; nach VDE-AR-N&nbsp;4105, Tabelle
                            2.
                        </>
                    }
                    register={register}
                />
                {(activePowerAbove25kW || solarPowerAbove25kWp) && (
                    <AcknowledgementCheckbox
                        trigger={trigger}
                        id="radioRippleControlReceiverInstalled"
                        title="Technische Einrichtung zur Reduzierung der Erzeugungsleistung (gemäß EEG ab 25 kW/kWp)"
                        label="Der Verbau eines Funkrundsteuerempfängers ist erfolgt und das Protokoll wurde dem Netzbetreiber übergeben."
                        register={register}
                    />
                )}
                {hasGenerationPlant && (
                    <AcknowledgementCheckbox
                        trigger={trigger}
                        id="reactivePowerProvisionGenerationPlant"
                        title="Blindleistungsbereitstellung"
                        label={
                            <>
                                Die Erzeugungsanlagen{!isSolarEnergy ? '¹' : ''} werden mit der Kennlinie
                                cos&nbsp;φ&nbsp;(P) nach Bild&nbsp;9 der VDE-AR-N&nbsp;4105 betrieben.
                                {determineReactivePowerProvisionGenerationPlantText(isSolarEnergy, isDCStorage)}
                            </>
                        }
                        register={register}
                    />
                )}
                {hasEnergyStorage && (
                    <AcknowledgementCheckbox
                        trigger={trigger}
                        id="reactivePowerProvisionEnergyStorage"
                        title={hasGenerationPlant ? undefined : 'Blindleistungsbereitstellung'}
                        label={
                            <>
                                Das Energiespeichersystem (ohne Lieferung in das öffentliche Netz und ohne
                                Leistungsbezug aus dem öffentlichen Netz) wird mit einem cos&nbsp;φ&nbsp;=&nbsp;1
                                betrieben.
                            </>
                        }
                        register={register}
                    />
                )}

                <AcknowledgementCheckbox
                    trigger={trigger}
                    id="installationOfBidirectionalMeter"
                    title="Zweienergierichtungszähler"
                    label="Ein Zweienergierichtungszähler ist bereits verbaut oder der Einbau ist beauftragt."
                    register={register}
                />

                {hasSinglePhaseInverter && <AcknowledgeSymmetryCondition register={register} />}

                {hasGenerationPlant && (
                    <CommissioningDateFormItem
                        title="Datum der Inbetriebsetzung"
                        label="Bitte geben Sie das Datum der Inbetriebsetzung der Erzeugungsanlage an."
                        id="technicalCommissioningDateOfGenerator"
                        register={register}
                        errors={errors}
                    />
                )}

                {hasEnergyStorage && (
                    <CommissioningDateFormItem
                        title={hasGenerationPlant ? undefined : 'Datum der Inbetriebsetzung'}
                        label="Bitte geben Sie das Datum der Inbetriebsetzung des Energiespeichers an."
                        id="technicalCommissioningDateOfStorage"
                        register={register}
                        errors={errors}
                    />
                )}
                {connectionAssurance.hasControllableConsumptionDevice && (
                    <span className="col-span-3 text-small">
                        <b>Hinweis: </b>
                        Das angegebene Datum wird auch als Inbetriebsetzungsdatum für die Anmeldung des Energiespeichers
                        als steuerbare Verbrauchseinrichtung gesetzt.
                    </span>
                )}
                <p className="col-span-4 font-bold mt-5">Erklärung zur Inbetriebsetzung</p>
                <div className="col-span-3">
                    Die Erzeugungsanlage/das Energiespeichersystem ist/sind nach VDE-AR-N&nbsp;4105, VDE-AR-N&nbsp;4100
                    und den technischen Anschlussbestimmungen (TAB) des Netzbetreibers errichtet worden. Als
                    Installationsbetrieb, habe ich den Anlagenbetreiber unterwiesen und eine vollständige Dokumentation
                    inkl. Schaltplan nach den jeweils gültigen VDE-Bestimmungen übergeben.
                </div>

                <AcknowledgementCheckbox
                    trigger={trigger}
                    id="declarationOfCommissioning"
                    label="Ich bestätige die Erklärung zur Inbetriebsetzung."
                    register={register}
                />
            </div>

            {showSubmitError && <ErrorBox onClick={() => setShowSubmitError(false)} />}
        </Form>
    );
};
