import { PlantComponentsTable } from '@/connection-assurance/commissioning/components/PlantComponentsTable';
import { RouteProperties } from '@/types/RouteProperties';
import { formatNumber, parseNumberFromString } from '@/utils/formatNumber';
import { calculateSumOfActivePowersInKW } from '@/utils/plantComponents';
import { REQUIRED_ERROR_MESSAGE } from '@/utils/textConstants';
import { validateNumberInput } from '@/utils/validation';
import {
    Close,
    Dropdown,
    Form,
    FormItem,
    FormLayout,
    InfoI,
    TeagPiktoGaststoerung800X800210816,
    TextField,
    Tooltip,
    createProcessGuidanceButtons,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CommissioningProcessProps, PlantComponentProps } from '../CommissioningProcessProps';
import { ElectricityMeterSerialNumberField } from '../components/ElectricityMeterSerialNumberField';
import { EnergyStorageCapacityErrorText } from '../components/EnergyStorageCapacityErrorText';
import { MasterDataRegisterNumberField } from '../components/MasterDataRegisterNumberField';
import { Context } from '../wizard/context';
import { useContextUpdate } from '../wizard/hooks';

export const PlantComponentsGenerator = ({
    onSubmit,
    goBack,
    context,
    title,
    connectionAssurance,
    allPlantComponents,
    updateContext,
}: RouteProperties & CommissioningProcessProps & PlantComponentProps) => {
    const plantComponentUsedForDcStorageIdFromConnectionAssurance =
        connectionAssurance.plantComponentsGenerator?.length > 1
            ? connectionAssurance.plantComponentsGenerator.find((p) => p.usedForDcStorage)?.plantComponentId?.toString()
            : undefined;

    const reactHookForm = useForm<Context>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            generatorData: context.generatorData,
            storageData: context.storageData,
            electricityMeterSerialNumber: context.electricityMeterSerialNumber,
            plantComponentUsedForDcStorageId:
                context.plantComponentUsedForDcStorageId ?? plantComponentUsedForDcStorageIdFromConnectionAssurance,
        },
    });

    const reactHookFormPlantComponents = useForm<Context>({
        mode: 'onTouched',
        shouldUnregister: true,
    });
    const {
        formState: { isValid: isPlantComponentValid },
    } = reactHookFormPlantComponents;

    const {
        formState: { isValid, errors },
        register,
        watch,
    } = reactHookForm;

    const newActivePower = calculateSumOfActivePowersInKW(context.plantComponentsGenerator);
    const oldActivePower = calculateSumOfActivePowersInKW(connectionAssurance.plantComponentsGenerator);

    const [maximumSolarModuleCapacityExceeded, setMaximumSolarModuleCapacityExceeded] = useState(false);
    const [maximumEnergyStorageCapacityExceeded, setMaximumEnergyStorageCapacityExceeded] = useState(false);

    const newComponentsActivePowerAllowed = newActivePower <= oldActivePower;

    const { nextButton, prevButton } = createProcessGuidanceButtons({
        isNextValid:
            isValid &&
            isPlantComponentValid &&
            context.plantComponentsGenerator?.length &&
            newComponentsActivePowerAllowed &&
            !maximumEnergyStorageCapacityExceeded &&
            !maximumSolarModuleCapacityExceeded,
        onBack: goBack,
        onNext: () => onSubmit(),
    });
    const isSolarEnergyGenerator =
        connectionAssurance.generatorData?.generalGeneratorType.generatorType === 'solarEnergy';
    const isEnergyStorageDcSystem = connectionAssurance.storageData?.energyStorageSystem === 'dcSystem';

    const showPlantComponentSelectionForDcStorage =
        connectionAssurance.plantComponentsGenerator?.some((p) => p.usedForDcStorage !== undefined) &&
        isEnergyStorageDcSystem &&
        context.plantComponentsGenerator.length > 1;

    useContextUpdate(watch, updateContext, 'PLANT_COMPONENTS_GENERATOR');

    useEffect(() => {
        setMaximumSolarModuleCapacityExceeded(
            parseNumberFromString(context.generatorData?.solarModuleCapacityInkWp) >= 3 * oldActivePower,
        );
    }, [context.generatorData?.solarModuleCapacityInkWp, oldActivePower]);

    useEffect(() => {
        if (isEnergyStorageDcSystem) {
            setMaximumEnergyStorageCapacityExceeded(
                parseNumberFromString(context.storageData?.energyStorageCapacityInkWh) > 100,
            );
        }
    }, [context.storageData?.energyStorageCapacityInkWh, context, isEnergyStorageDcSystem, connectionAssurance]);

    const sumOfAllSolarModuleCapacitiesLabel = (
        <div className="inline">
            <span>Wie hoch ist die gesamte Leistung aller Solarmodule?</span>
            <div
                className="inline-block ml-2 w-3 overflow-visible fill-current text-primary
                        hover:text-dark-blue"
            >
                <InfoI
                    text={
                        <span>
                            Sie finden diese in den technischen Daten der Solarmodule. Bitte geben Sie diese in Kilowatt
                            Peak (kW<sub>p</sub>) an.
                        </span>
                    }
                />
            </div>
        </div>
    );

    return (
        <Form title={title} leftButton={prevButton} rightButton={nextButton} handleSubmit={() => onSubmit()}>
            <h4 className="font-bold text-subheading">Anlagenkomponenten der Erzeugungsanlage</h4>
            <p>
                Bitte prüfen Sie die Angaben auf Korrektheit und passen diese bei Bedarf an. Sie können die
                Wechselrichter verändern, wenn die Wirkleistung gleich bleibt.
            </p>
            <div className="flex flex-col">
                <FormProvider {...reactHookFormPlantComponents}>
                    <PlantComponentsTable
                        plantComponentType="plantComponentsGenerator"
                        contextPlantComponents={context.plantComponentsGenerator}
                        allPlantComponents={allPlantComponents}
                        updateContext={updateContext}
                    />
                </FormProvider>

                <FormProvider {...reactHookForm}>
                    <FormLayout>
                        {showPlantComponentSelectionForDcStorage && (
                            <FormItem
                                label="An welchen der zuvor ausgewählten Wechselrichter ist der DC-Energiespeicher angeschlossen?"
                                className="mb-8"
                            >
                                <Dropdown
                                    id="dc-storage-plant-component-dropdown"
                                    data-testid="dc-storage-plant-component-dropdown"
                                    dropdownClassname="w-[32rem]"
                                    options={context.plantComponentsGenerator.map((plantComponent) => ({
                                        value: plantComponent.plantComponentId?.toString(),
                                        label: plantComponent.plantComponentName,
                                    }))}
                                    {...register('plantComponentUsedForDcStorageId', {
                                        required: true,
                                    })}
                                />
                                {errors?.plantComponentUsedForDcStorageId && (
                                    <div>
                                        <Tooltip message={REQUIRED_ERROR_MESSAGE} />
                                    </div>
                                )}
                            </FormItem>
                        )}

                        {isSolarEnergyGenerator && (
                            <FormItem label={sumOfAllSolarModuleCapacitiesLabel}>
                                <div className="flex gap-16 items-start">
                                    <div className="flex gap-x-2 items-center">
                                        <div className="w-24">
                                            <TextField
                                                id="solar-module-capacity"
                                                data-testid="solar-module-capacity"
                                                tooltipClassNames="w-max"
                                                displayErrorMessage={
                                                    errors.generatorData?.solarModuleCapacityInkWp?.message
                                                }
                                                {...register('generatorData.solarModuleCapacityInkWp', {
                                                    validate: (capacity) => validateNumberInput(capacity),
                                                    required: REQUIRED_ERROR_MESSAGE,
                                                    shouldUnregister: true,
                                                })}
                                            />
                                        </div>
                                        <span>
                                            kW<sub>p</sub>
                                        </span>
                                    </div>
                                    {maximumSolarModuleCapacityExceeded && (
                                        <div
                                            className="-my-3 flex space-x-5 -mr-4"
                                            data-testid="solar-module-capacity-error"
                                        >
                                            <span
                                                className="flex w-10 text-primary items-center fill-current"
                                                data-testid="cross"
                                            >
                                                <Close />
                                            </span>
                                            <div className="flex w-full flex-col justify-center items-center">
                                                Die eingegebene Solarmodulleistung ist im Vergleich zu den
                                                Wechselrichtern zu hoch. Bitte überprüfen Sie Ihre Eingaben und passen
                                                diese an. Der Wert darf {formatNumber(oldActivePower * 3, 1, 1)}
                                                &nbsp;kWp nicht übersteigen.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </FormItem>
                        )}
                        {isEnergyStorageDcSystem && (
                            <FormItem label="Wie hoch ist die nutzbare Speicherkapazität des Energiespeichers?">
                                <div className="flex gap-16">
                                    <div className="flex gap-x-2 items-center">
                                        <div className="w-24">
                                            <TextField
                                                id="energy-storage-capacity"
                                                data-testid="energy-storage-capacity"
                                                tooltipClassNames="w-max"
                                                displayErrorMessage={
                                                    errors.storageData?.energyStorageCapacityInkWh?.message
                                                }
                                                {...register('storageData.energyStorageCapacityInkWh', {
                                                    validate: (capacity) => validateNumberInput(capacity),
                                                    required: REQUIRED_ERROR_MESSAGE,
                                                })}
                                            />
                                        </div>
                                        <span>kWh</span>
                                    </div>
                                    {maximumEnergyStorageCapacityExceeded && <EnergyStorageCapacityErrorText />}
                                </div>
                            </FormItem>
                        )}

                        <MasterDataRegisterNumberField showGeneration showStorage={isEnergyStorageDcSystem} />
                        <ElectricityMeterSerialNumberField />
                    </FormLayout>
                </FormProvider>
            </div>
            {!newComponentsActivePowerAllowed && (
                <div className="flex gap-x-4 bg-white p-4 shadow-ten" data-testid="error-active-power">
                    <div className="flex items-center w-20">
                        <TeagPiktoGaststoerung800X800210816 className="fill-current text-primary" />
                    </div>
                    <div className="flex items-center pr-4">
                        Die Änderungen ergeben eine Gesamtleistung von {formatNumber(newActivePower)} kW, diese Leistung
                        liegt über der zugesicherten Maximalleistung von {formatNumber(oldActivePower)} kW. Sollte die
                        bisher zugesicherte maximale Einspeiseleistung für die gewünschten Komponenten nicht ausreichen,
                        muss ein neuer Einspeisungsantrag gestellt werden.
                    </div>
                </div>
            )}
        </Form>
    );
};
